import graphql from "babel-plugin-relay/macro";

const AvailableDiscountsQuery = graphql`
  query AvailableDiscountsQuery($listType:TypeEnum!) {
	  availableDiscounts(listType:$listType){
        id
        name
        code
        description
      }
  }
`;

export default AvailableDiscountsQuery;