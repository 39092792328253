import React from 'react'
import './BottomModalComponent.scss'
import '../../assets/common/common_style.scss'
import {MDBBox, MDBModal, MDBModalBody} from "mdbreact";
import CloseIcon from "../../assets/icons/cross-icon-simple-darkgold.svg";

const BottomModalComponent = (props) => {
	const {title, closeModal, isOpen, children} = props
	return (
		<div className={'bottom-modal-component'}>
			<MDBModal isOpen={isOpen} fullHeight position="bottom">
				<MDBModalBody>
					<MDBBox className={"modal-heading d-flex align-items-center justify-content-between"}>
					<span className={"title"}>
                        {title}
                    </span>
						<img src={CloseIcon} alt={"close"} className={"img-fluid"} onClick={closeModal}/>
					</MDBBox>
					{children}
				</MDBModalBody>
			</MDBModal>

		</div>
	)
}

export default BottomModalComponent
