// default pages
import cart_data_1 from "../assets/images/chocolate-1/fabelle-detailchocolate-1-1.png";
import cart_data_2 from "../assets/images/fabelle-detailchocolate-2-1.png";

// backend url ******************************

// testing - backend
// export const api = 'https://ds.in.ngrok.io/graphql/'
// export const api = 'https://fe.in.ngrok.io/graphql/'
// export const api='https://sh.in.ngrok.io/graphql/'

// development - backend
export const api = process.env.REACT_APP_API;





// staging - backend
// export const api = 'https://loc-app.fabelle.in/graphql/';

// live - backend
// export const api = "https://app.fabelle.in/graphql/"

//testing key - old
// export const Razorpay_Key = "rzp_test_8VImv62UL3Lmvc";

//testing key
// export const Razorpay_Key = "rzp_test_g5XWus5nlfJQvn";

//testing key - 2
// export const Razorpay_Key = "rzp_test_0f1IJowIRTBW15";

//******************************************************

// razor pay keys*********************************

// live key
// export const Razorpay_Key = "rzp_live_q6Tzc798zgV48f"

// live key 2
export const Razorpay_Key = process.env.REACT_APP_RAZORPAY_KEY;


// *********************************************

// image path
export const image_path = "https://s3.ap-south-1.amazonaws.com/fabelle.in/";


// *********************************************

export const fabelle_pages = [
	{route: '/', name: 'home'},
	{route: '/product-list', name: 'Shop'},
	{route: '/boutiques', name: 'Store Locator'},
	{route: null, name: 'Gifting'},
	{route: '/about-us', name: 'Our Story'},
	{route: 'track-order', name: 'Track Order'},
]
export const fabelle_footer_pages = [
	{page:'Home',route:'/'},
	{page:'Shop',route:'/product-list'},
	{page:'Store Locator',route:'/boutiques'},
	{page:'My Orders',route:'/track-order'},
	// {page:'privacy policy',route:'/privacy-policy'},
	// {page:'terms and conditions',route:'/terms-condition'},
	// {page:'Refunds and Cancellation',route:'/refund-policy'},
	// {page:'Billing Terms and Conditions',route:'/billing-terms-condition'},
	{page:'About Fabelle',route:'/about-us'},
	{page:'Masterclass',route:'/masterclass'},
	{page:'Contact Us',route:'/contact'},
]
export const fabelle_footer_pages_mobile = [
	{page:'Home',route:'/'},
	{page:'Shop',route:'/product-list'},
	{page:'Store Locator',route:'/boutiques'},
	// {page:'gifting',route:'/product-list/collection/'},
	{page:'My Orders',route:'/track-order'},
	{page:'Privacy Policy',route:'/privacy-policy'},
	{page:'Terms of Use',route:'/terms-use'},
]
export const fabelle_footer_pages_mobile_2 = [
	{page:'Cart',route:'cart-modal'},
	// {page:'profile',route:'/'},
	{page:'About Fabelle',route:'/about-us'},
	{page:'Masterclass',route:'/masterclass'},
	{page:'Contact Us',route:'/contact'},
	{page:'Returns, Refund and Cancellation',route:'/refund-policy'},
	{page:'Billing Terms and Conditions',route:'/billing-terms-condition'},
	{page:'Sitemap',route:'/sitemap'}
]
export const fabelle_chocolates = [
	'Truffles',
	'Chocolate with Nuts',
	'Boutique Creations',
	'Dark Chocolates',
]
export const fabelle_footer_more = [
	{page:'About Fabelle',route:'/about-us'},
	{page:'Contact Us',route:'/contact'},
	{page:'Cart',route:'/checkout'},
	{page:'Masterclass',route:'/masterclass'},
]
export const fabelle_footer_information= [
	{page:'Privacy Policy',route:'/privacy-policy'},
	{page:'Terms of Use',route:'/terms-use'},
	{page:'Returns, Refund and Cancellation',route:'/refund-policy'},
	{page:'Billing Terms and Conditions',route:'/billing-terms-condition'},
	{page:'Sitemap',route:'/sitemap'}
]
export const fabelle_footer_all = [
	// {page:'gifting',route:'/'},
	{page:'Profile',route:'/'},
	{page:'Wishlist',route:'/'},
	{page:'Cart',route:'/checkout'},
]
export const fabelle_sidebar = [
	{page: 'home', subPage: null, route: '/'},
	{
		page: 'shop', subPage: [
			{page: 'Boxed Chocolates', route: '/product-list'},
			{page: 'Dark Chocolates', route: '/product-list'},
			{page: 'Milk Chocolates', route: '/product-list'},
			{page: 'Premium Bars', route: '/product-list'},
			{page: 'Luxury Bars', route: '/product-list'},
			{page: 'Premium Bars', route: '/product-list'},
			{page: 'Gift Chocolates', route: '/product-list'},
			{page: 'Cakes', route: '/product-list'},
			{page: 'Beverages', route: '/product-list'},
			{page: 'Truffles', route: '/product-list'},
			{page: 'Chocolate with nuts', route: '/product-list'},
		]
	},
	{page: 'Boutiques', subPage: null, route: '/boutiques'},
	{page: 'gifting', subPage: null, route: null},
	{page: 'track order', subPage: null, route: '/track-order'},
	{page: 'cart', subPage: null, route: "/checkout"},
	{page: 'profile', subPage: null, route: null},
	{page: 'about fabelle', subPage: null, route: '/about-us'},
	{page: 'masterclass', subPage: null, route: '/masterclass'},
	{page: 'store locators', subPage: null, route: '/contact'},
	{page: 'contact us', subPage: null, route: '/contact'}
]

export const cart_data = [
	{
		id: 1,
		name: 'Fabelle Dark Choco Mousse',
		description: ' Centre Filled Bar, 135g',
		image: cart_data_1,
		price: 332.50,
		originalPrice: 350,
		offer: 5
	},
	{
		id: 2,
		name: 'Fabelle Dessert Collection',
		description: ' Luxury Chocolate Box of 10 Handcrafted Truffles',
		image: cart_data_2,
		price: 1150,
		originalPrice: 1300,
		offer: 5
	}
]

export const availableStates = [
	"Tamil Nadu",
	"Kerela",
	"Andhra pradesh",
	"Karnataka",
	"Telungana"
]
export const availableCity = [
	// {name:"Coimbatore"},
	// {name:"Chennai"},
	// {name:"Bengaluru"},
	// {name:"Vizag"},
	// {name:"Hydrabad"},
	// {name:"Kochi"}
	{name:"Bangalore"},
	{name:"Hyderabad"},
	{name:"Mumbai"},
	{name:"Chennai"},
	{name:"Pune"},
	// {name:"Ahmedabad"},
	{name:"Kolkata"},
	{name:"Delhi"}
]

export const OrderByEnums = [
	"NAME_ASC",
	"NAME_DESC",
	"BEST_SELLING"
]
