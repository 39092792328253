import graphql from 'babel-plugin-relay/macro'

const ProductVariantByIdQuery = graphql`
	query ProductVariantByIdQuery($variantId:String) {
	productVariantById(variantId:$variantId) {
   
            id
            variantId
            isAvailable
             getPrice
            getOriginalPrice
            featuredImage
             discountedPrice
            discountPercentage
            sku
        isInWishlist
            product {
                id
                name
                rating
                description
                price
                weight
                discountedPrice
                masterSku
                featuredImage
                otherInformation
                learnMore
                collection{
                	name
                }

            }
            variantImages{
			  id
			  image
			  isFeatured
			}
            faqs {
                id
                question
                answer
            }
            variantInfoImages{
                edges{
                    node{
                    id
                    imageUrl
                    videoUrl
                    type
                    isActive
                    sortOrder
                    }
                }
             }
        }
}
	`

export default ProductVariantByIdQuery
// category {
//     id
//     name
// }
