import React, {useEffect, useState} from 'react'
import './DesktopLogin.scss'
import '../../assets/common/common_style.scss'

import cross from '../../assets/icons/cross-icon-simple-darkgold.svg'
import LoginComponent from "../AuthComponents/LoginComponent";
import VerifyOtpComponent from "../AuthComponents/VerifyOtpComponent";
import {secondsToTime} from "../../utils/utilis";
import LoginWithMobileMutation from "../../mutations/LoginWithMobileMutation";
import cogoToast from "cogo-toast";
import VerfiyOtpMutation from "../../mutations/VerifyOtpMutation";
import {useMediaQuery} from "react-responsive";
import {CustomToast} from "../CustomToast/CustomToast";
import CustomButtonPrimary from "../CustomButton/CustomButtonPrimary";
import {useNavigate} from "react-router-dom";
import {useLocation} from "react-router";
import {fbPixel_completeRegistration} from "../../utils/FB_pixel_events";
import {setUserDetail} from "../../store/actions/AuthActions";
import {connect} from "react-redux";

const DesktopLogin = (props) => {
	const {closeModal,showCloseOption} = props
	const isDesktopOrLaptop = useMediaQuery({minWidth: 1024})
	let intervalId
	let seconds = 120
	const navigate = useNavigate()
	const path = useLocation().pathname


	const [isSignIn, setIsSignIn] = useState(true);
	const [valid, setValid] = useState(false);
	const [mobileNumber, setMobileNumber] = useState('');
	const [inputDirty, setInputDirty] = useState(0);
	const [typing, setTyping] = useState(false);
	const [checked, setChecked] = useState(false);
	const [time, setTime] = useState({});
	const [otpValue, setOtpValue] = useState('');
	const [timer, setTimer] = useState(null);

	useEffect(() => {
		if("OTPCredential" in window){
			const ac = new AbortController();
			navigator.credentials.get({
				otp:{transport:["sms"]},
				signal: ac.signal
			}).then((otp)=>{
				setOtpValue(otp.code)
				ac.abort()
			}).catch((err)=>{
				ac.abort()
				console.log(err)
			})
		}
	}, []);

	const signInOperation = () => {
		let variable = {
			"mobileNumber": mobileNumber
		}

		if(mobileNumber === ""){
			cogoToast.info(<CustomToast type={"info"} message={"please fill valid mobile number"}/>,{position: "top-center"});
		} else if(mobileNumber.length < 10){
			cogoToast.info(<CustomToast type={"info"} message={"mobile number should be 10 digits"}/>,{position: "top-center"});

		} else {

			LoginWithMobileMutation(variable, (response) => {
				if (response.loginWithMobile !== null) {
					cogoToast.success(<CustomToast type={"success"} message={response.loginWithMobile.message}/>,{position: "top-center"});
					setIsSignIn(false)

				}
			}, (err) => {
				cogoToast.error(<CustomToast type={"error"} message={err?.message}/>,{position: "top-center"});
			})
		}

	}
	const verifyOtpOperation = () => {

		let variable = {
			"mobileNumber": mobileNumber,
			"otp": otpValue
		}
		// setTimeout(()=>{
		// 	if(!verifyingOtp){
				VerfiyOtpMutation(variable, (response) => {
					if (response.verifyOtp !== null) {
						cogoToast.success(<CustomToast type={"success"} message={"Successfully Signed In "}/>,{position: "top-center"});
						props.setUserDetailToStore(response.verifyOtp.user);
						localStorage.setItem('token',response.verifyOtp.token)
						localStorage.setItem('refreshToken',response.verifyOtp.refreshToken )
						// registrationGA_II()
						fbPixel_completeRegistration()
						closeModal()
						window.location.reload();
					}
				}, (err) => {
					cogoToast.error(<CustomToast type={"error"} message={err?.message}/>,{position: "top-center"});
				})
		// 	}
		// },3000)

	}
	const resendOtpOperation = () => {
		let variable = {
			"mobileNumber": mobileNumber
		}
		LoginWithMobileMutation(variable, (response) => {
			if (response.loginWithMobile !== null) {
				cogoToast.success(<CustomToast type={"success"} message={response.loginWithMobile.message}/>,{position: "top-center"});

				// setIsSignIn(false)
			}
		}, (err) => {
			cogoToast.error(<CustomToast type={"error"} message={err?.message}/>,{position: "top-center"});
		})

	}

	useEffect(() => {
		if (otpValue.length === 4) {

			verifyOtpOperation()
		}
	}, [otpValue]);

	useEffect(() => {
		let timeLeftVar = secondsToTime(seconds);

		setTime(timeLeftVar)
		startTimer();

	}, []);

	useEffect(() => {

	}, [isSignIn]);

	// useEffect(()=>{
	// 	if(otpValue.length === 4){
	// 		verifyOtpOperation();
	// 	}
	// },[otpValue])

	const startTimer = () => {

		if (seconds > 0) {
			// let self = this;

			intervalId = setInterval(function () {
				let second = seconds - 1;
				setTime(secondsToTime(seconds))
				seconds = second

				// Check if we're at zero.
				if (second === 0) {
					clearInterval(intervalId);
				}
			}, 1000);
			setTimer(intervalId)

		}
	}

	const resendOtpHandler = () => {

		setTime(secondsToTime(seconds))
		setTimer(null)
		clearInterval(timer);

		resendOtpOperation()
		startTimer()
		// cogoToast.success("OTP has been re-sent ")
	}

	const mobileNavigationHandler = () => {

		if (isSignIn) {
			closeModal()
			if(path === '/checkout'){
				navigate('/',{replace:false})
			}
		} else {
			setIsSignIn(true)
		}
	}

	const changeMobileNumber=()=>{
		setIsSignIn(true)
	}

	const closeModalHandler=()=>{
		closeModal()
		if(!isDesktopOrLaptop && path === '/checkout'){
			navigate('/',{replace:false})
		}
	}

	const closeModalHandlerForDesktop = () => {
		closeModal()
		if(path === '/checkout'){
			navigate('/',{replace:false})
		}
	}


	return (
		<div className={'desktop-login-component'}>
			{
				!isDesktopOrLaptop && showCloseOption &&
				<div className={'mobile-top-bar d-flex align-items-center'}>
					<img src={ cross}
					     onClick={closeModalHandler}
					     className={'icon-24 mr-2'} alt={'alt'}/>
					{/*<p className={'euphemia-400-20 fabelle-primary-gold mb-0'}>Sign in</p>*/}
				</div>
			}
			<div className={'row'}>
				{isDesktopOrLaptop && <div className={'col-12 col-lg-6 login-image'}/>}
				<div className={'col-12 col-lg-6 content-area'}>
					{isDesktopOrLaptop && showCloseOption && <div className={'navigation-box'}>
						{/*<img style={isSignIn ? {visibility: 'hidden'} : {visibility: 'visible'}}*/}
						{/*     onClick={() => setIsSignIn(true)}*/}
						{/*     src={backArrow} alt={'alt'} className={'ico-1 icon-24'}/>*/}
						<img
							// style={!isSignIn ? {visibility: 'hidden'} : {visibility: 'visible'}}
						     onClick={closeModalHandlerForDesktop}
						     src={cross} alt={'alt'}
						     className={'ico-2 icon-24'}/>
					</div>}
					<div className={'inner-content'}>
						<div className={'w-100'}>
							{
								isSignIn ?
									<LoginComponent signInOperation={signInOperation}
									                checked={checked}
									                mobileNumber={mobileNumber}
									                setChecked={setChecked}
									                setInputDirty={setInputDirty}
									                inputDirty={inputDirty}
									                valid={valid}
									                setValid={setValid}
									                setTyping={setTyping}
									                // closeModal={closeModal}
									                setMobileNumber={setMobileNumber}/>
									:
									(
										<>

											<VerifyOtpComponent
												setOtpValue={(args)=>{
													setOtpValue(args)
												}}
												time={time}
												resendOtpHandler={resendOtpHandler}
												otpValue={otpValue}
												changeMobileNumber={changeMobileNumber}
											/>
										</>
								)
							}
						</div>
					</div>

				</div>
			</div>
			{
				!isDesktopOrLaptop && isSignIn &&
				<div className={'d-flex justify-content-center mobile-bottom-button-section'}>
					<CustomButtonPrimary  theme={'light'} name={"Continue"}
					               className={'euphemia-700-16 w-100'}
					               disabled={mobileNumber.length !== 10}
								  clickHandler={signInOperation}/>
				</div>
			}
		</div>
	)
}

const mapDispatchToProps = dispatch => ({
	setUserDetailToStore: (data) => dispatch(setUserDetail(data))
})

export default connect(null,mapDispatchToProps)(DesktopLogin);

