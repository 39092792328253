import {commitMutation} from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import environment from '../environment'

const mutation = graphql`
 mutation VerifyOtpMutation($mobileNumber:String!,$otp:String!){
    verifyOtp(mobileNumber:$mobileNumber,otp:$otp){
        token
        refreshToken
        message
        user{
			id
			email
			username
			mobileNumber
			firstName
			lastName
			isActive
        }
    }
 }
`

export default (data, callback, errCallback) => {
	const variables = {
		mobileNumber: data.mobileNumber,
		otp:data.otp
	};

	commitMutation(
		environment,
		{
			mutation,
			variables,

			onCompleted: (response, err) => {

				if (response.verifyOtp !== null) {
					callback(response)
				} else {
					errCallback(err[0].message);
				}

			},
			onError: err => {

				errCallback(err);
			},
		},
	)
}

