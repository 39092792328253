import React, {useEffect, useRef, useState} from 'react'
import './ProductCard.scss'
import {useMediaQuery} from "react-responsive";

import minus from '../../assets/icons/minus-icon.svg'
import plus from '../../assets/icons/Plus-icon.svg'
import cartMobile from '../../assets/icons/mobile-product-cart-icon.svg'
import filledRating from '../../assets/icons/product-rating-filled.svg'
import unfilled from '../../assets/icons/product-rating-empty.svg'
// import wishlistInactive from '../../assets/icons/wishlist-inactive.svg'
import wishlistInactive from '../../assets/icons/wishlist-inactive-with-bg.svg'
// import wishlistActive from '../../assets/icons/wishlist-active-icon.svg'
import wishlistActive from '../../assets/icons/wishlist-active-icon-with-bg.svg'
import filledRating_mobile from '../../assets/icons/product-rating-filled-light.svg'
import unfilled_mobile from '../../assets/icons/product-rating-empty-grey.svg'

import {CustomButton} from "../CustomButton/CustomButton";
import {MDBBox} from "mdbreact";
import {setActiveSingleProductData, setActiveSingleProductId} from "../../store/actions/ProductActions";
import cogoToast from "cogo-toast";
import {connect} from 'react-redux'
import CreateCheckoutMutation from "../../mutations/CreateCheckoutMutation";
import CheckoutLineAddMutation from "../../mutations/CheckoutLineAddMutation";
import {
	addDataToCheckout,
	setCartItem,
	setCartModalState,
	setCheckoutId,
	updateCart
} from "../../store/actions/CheckoutActions";
import {Link} from "react-router-dom";
import FeaturedProductImg from "../../assets/images/fabelle-detailchocolate-10-1.png";
import {useLocation, useNavigate} from "react-router";
import uuid from 'react-uuid'
import {CustomToast} from "../CustomToast/CustomToast";
import {combineArrToSentence} from "../../utils/utilis";
import LinesEllipsis from "react-lines-ellipsis";
import {
	sendAddToCartToGA, sendAddToWishlistToGA,
	sendProductClickToGA,
	sendProductItemSelectToGA,
	sendRemoveFromCartToGA
} from "../../utils/GA-Events";
import createOrUpdateReviewMutation from "../../mutations/createOrUpdateReviewMutation";
import AddToWishlistMutation from "../../mutations/AddToWishlistMutation";
import RemoveFromWishlistMutation from "../../mutations/RemoveFromWishlistMutation";
import CustomButtonSecondary from "../CustomButton/CustomButtonSecondary";
import checkoutLineDeleteUsingVariantIdMutation from "../../mutations/CheckoutLineDeleteUsingVariantIdMutation";
// import {wishlistGA_II} from "../../utils/GA-Events-II";
import {fbPixel_addToCart, fbPixel_addToWishlist} from "../../utils/FB_pixel_events";
import SolidStar from "../../assets/icons/star-solid.svg";
import EmptyStar from "../../assets/icons/star-regular.svg";
import HalfStar from "../../assets/icons/star-half-stroke-solid.svg";
import {Rating} from "react-simple-star-rating";
import ProductRating from "../ProductRating/ProductRating";

const ProductCard = (props) => {
	const {
		id, featuredImage, name, rating,
		price, originalPrice, offer, variantId, isAvailable,
		checkoutIdFromStore, setCartDataToStore,
		productData,
		cartItemFromStore, isInWishlist, setRefreshCount, refreshCount,
		discountedPrice, discountPercentage, removeWishlistFromWishlistPage
	} = props
	const imageEl = useRef(null);
	const isLogin = localStorage.getItem('token')
	const isDesktopOrLaptop = useMediaQuery({minWidth: 1224})
	const navigate = useNavigate()
	const pathname = window.location.pathname
	const [quantity1, setQuantity] = useState(0);
	const [wishlistId, setWishlistId] = useState('')
	const [showAction, setShowAction] = useState(false);

//  const [isAddedToCart, setIsAddedToCart] = useState(false);


	useEffect(() => {
		if (cartItemFromStore && cartItemFromStore.length > 0) {
			let tmp_quantity = 0;
			let index = cartItemFromStore.findIndex(item => item.node.variant.id === id);
			if (index > -1) {
				tmp_quantity = cartItemFromStore[index].node.quantity;
				setQuantity(tmp_quantity);
			}
		} 		

	}, []);

	useEffect(() => {
		if (cartItemFromStore && cartItemFromStore.length > 0) {
			let tmp_quantity = 0;
			let index = cartItemFromStore.findIndex(item => item.node.variant.id === id);
			if (index > -1) {
				tmp_quantity = cartItemFromStore[index].node.quantity;
				setQuantity(tmp_quantity);
				if (tmp_quantity > 0) {
					setShowAction(true)
				} else {
					setShowAction(false)
				}
			}
		} 
		else if(cartItemFromStore.length === 0){
			setQuantity(0);
			setShowAction(false)
		}
	}, [cartItemFromStore]);

	const createCheckout = () => {
		let variable = {
			"quantity": 1,
			"variantId": id
		}
		CreateCheckoutMutation(variable, (response) => {
			if (response.checkoutCreate !== null) {
				cogoToast.success(<CustomToast type={"success"} message={"Added To Cart"}/>, {position: "top-center"});
				let cartObj = {
					checkoutId: response.checkoutCreate.checkout.id,
					checkout: response.checkoutCreate.checkout,
					cartItems: response.checkoutCreate.checkout.cartItems.edges
				}
				setCartDataToStore(cartObj);
				addToCartGA(1);
				addToCartFbPixel()
			} else {
				cogoToast.error(<CustomToast type={"error"}
											 message={"Something went wrong"}/>, {position: "top-center"});
			}

		}, (err) => {
			cogoToast.error(<CustomToast type={"error"} message={err?.message}/>, {position: "top-center"});
		})
	}

	const addItemToCart = (quantity) => {
		let variable = {
			"checkoutId": checkoutIdFromStore,
			"quantity": quantity === 0 ? 1 : quantity,
			"variantId": id
		}
		CheckoutLineAddMutation(variable, (response) => {
			if (response.checkoutLineAdd !== null) {
				cogoToast.success(<CustomToast type={"success"} message={"Added To Cart"}/>, {position: "top-center"});
				let cartObj = {
					checkoutId: response.checkoutLineAdd.checkout.id,
					checkout: response.checkoutLineAdd.checkout,
					cartItems: response.checkoutLineAdd.checkout.cartItems.edges
				}
				setCartDataToStore(cartObj);
				addToCartGA(quantity);
				addToCartFbPixel()
			} else {
				cogoToast.error(<CustomToast type={"error"}
											 message={"Something went wrong"}/>, {position: "top-center"});
			}

		}, (err) => {
			cogoToast.error(<CustomToast type={"error"} message={err?.message}/>, {position: "top-center"});
		})

	}

	const addToCartHandler = () => {
		incrementCount();
		setShowAction(true);
	}

	const incrementCount = () => {
		let tmp_quantity = quantity1;
		if (tmp_quantity > -1) {
			tmp_quantity = tmp_quantity + 1;
		} else {
			tmp_quantity = 0;
		}
		if (checkoutIdFromStore !== "") {
			addItemToCart(tmp_quantity)
		} else {
			createCheckout()
		}
		setQuantity(tmp_quantity);
	}

	const removeCartItem = () => {
		let variable = {
			variantId: id,
			cartId: checkoutIdFromStore
		}
		checkoutLineDeleteUsingVariantIdMutation(variable, (response) => {
			if (response.checkoutLineDeleteUsingVariantId !== null) {
				let cartObj = {
					checkoutId: response.checkoutLineDeleteUsingVariantId.checkout.id,
					checkout: response.checkoutLineDeleteUsingVariantId.checkout,
					cartItems: response.checkoutLineDeleteUsingVariantId.checkout.cartItems.edges
				}
				setCartDataToStore(cartObj);
				// removeFromCartGA(lineItem);
				cogoToast.success(<CustomToast type={"success"}
											   message={"Removed Successfully"}/>, {position: "top-center"});
			} else {
				cogoToast.error(<CustomToast type={"error"}
											 message={"Something went wrong"}/>, {position: "top-center"});
			}
		}, (err) => {
			cogoToast.error(<CustomToast type={"error"} message={err?.message}/>, {position: "top-center"});
		})
	}

	const decrementCount = () => {
		let tmp_quantity = quantity1;

		if (tmp_quantity > 1) {
			tmp_quantity = tmp_quantity - 1;
		} else {
			tmp_quantity = 0;
			setShowAction(false);
		}
		setQuantity(tmp_quantity);
		removeCartItem();
		removeFromCartGA(1)

	}

	const redirectToDetailPage = () => {
		navigate("/product-detail/" + id, {replace: false})
	}

	const removeWishList = (id) => {
		let variable = {
			"variantId": id,
		}
		RemoveFromWishlistMutation(variable, (response) => {
			if (response !== null) {
				setWishlistId("")
				setRefreshCount(refreshCount + 1)
				cogoToast.success(<CustomToast type={"success"}
											   message={response.removeFromWishlist.message}/>, {position: "top-center"});
			} else {
				cogoToast.error(<CustomToast type={"error"}
											 message={"Something went wrong"}/>, {position: "top-center"});
			}

		}, (err) => {
			cogoToast.error(<CustomToast type={"error"} message={err?.message}/>, {position: "top-center"});
		})
	}

	const addWishList = (id) => {
		let variable = {
			"variantId": id,
		}
		AddToWishlistMutation(variable, (response) => {
			if (response !== null) {
				setWishlistId(id)
				setRefreshCount(refreshCount + 1)
				// wishlistGA_II(productData.getPrice)
				addToWishlistFbPixel()
				let product_formate = {
					item_id: productData.sku,
					item_name: productData.product.name,
					currency: "INR",
					discount: productData.discountPercentage,
					item_brand: "Fabelle",
					item_category: productData?.product?.collection ? productData.product.collection.name : "",
					item_variant: productData.variantId,
					price: productData.getPrice,
					quantity: 1
				}
				sendAddToWishlistToGA(product_formate)
				cogoToast.success(<CustomToast type={"success"}
											   message={response.addToWishlist.message}/>, {position: "top-center"});
			} else {
				cogoToast.error(<CustomToast type={"error"}
											 message={"Something went wrong"}/>, {position: "top-center"});
			}

		}, (err) => {
			cogoToast.error(<CustomToast type={"error"} message={err?.message}/>, {position: "top-center"});
		})
	}

	const addToWishlistFbPixel = () => {
		let data = {
			content_ids: productData.sku,
			content_type: 'product_group',
			value: productData.getPrice,
			currency: 'INR',
			num_items: 1,
			content_name: productData.product.name,
			content_category: productData.product && productData.product.collection ? productData.product.collection.name : "",

		}
		fbPixel_addToWishlist(data)
	}

	const addToCartGA = (qty) => {
		let product_formate = {
			sku: productData.sku,
			name: productData.product.name,
			collection: productData.product && productData.product.collection ? productData.product.collection.name : "",
			price: productData.getPrice,
			quantity: qty
		}
		sendAddToCartToGA(product_formate);
		// addToCartGA_II(productData.getPrice)
	}

	const addToCartFbPixel = () => {
		let data = {
			content_ids: productData.sku,
			content_type: 'product_group',
			value: productData.getPrice,
			currency: 'INR',
			num_items: 1,
			content_name: productData.product.name,
			content_category: productData.product && productData.product.collection ? productData.product.collection.name : "",

		}
		fbPixel_addToCart(data)
	}

	const removeFromCartGA = (qty) => {
		let product_formate = {
			sku: productData.sku,
			name: productData.product.name,
			collection: productData.product && productData.product.collection ? productData.product.collection.name : "",
			price: productData.getPrice,
			quantity: qty
		}
		sendRemoveFromCartToGA(product_formate);
	}

	const wishlistHandler = (id) => {

		if (productData.isInWishlist || isInWishlist) {

			if (pathname.includes("/wishlist")) {
				removeWishlistFromWishlistPage(id)
			} else {

				removeWishList(id);

			}

		} else {
			addWishList(id)

		}
	}

	const selectEventToGa = () => {
		let product_formate = {
			item_id: productData.sku,
			item_name: productData.product.name,
			currency: "INR",
			discount: productData.discountPercentage,
			item_brand: "Fabelle",
			item_category: productData?.product?.collection ? productData.product.collection.name : "",
			item_variant: productData.variantId,
			price: productData.getPrice,
			quantity: 1
		}
		sendProductClickToGA(product_formate);
	}

	return (
		<>
			<div className={'product-card-component d-flex flex-column justify-content-between'}
				 onClick={selectEventToGa}>
				{isLogin && <img
					src={wishlistId === id || (productData.isInWishlist || isInWishlist) ? wishlistActive : wishlistInactive}
					alt={'alt'}
					className={'icon-24 wishlist-icon'} onClick={() => wishlistHandler(id)}/>}
				<div>
					<div className={'d-flex justify-content-center'}>
						{/* product image */}
						<Link to={"/product-detail/" + id}>
							<img src={featuredImage}
								 alt={'alt'} className={'featured-image mx-auto cursor-pointer'}
								 ref={imageEl}
								 onError={() => {
									 imageEl.current.src = FeaturedProductImg;
								 }}
							/>
						</Link>
					</div>
					{/*<br/>*/}
					{isDesktopOrLaptop &&
						<><Link to={"/product-detail/" + id}>
							<p style={isDesktopOrLaptop ? {
								marginBottom: "8px",
								height: '46px'
							} : {marginBottom: "10px"}}
							   className={' d-flex justify-content-center align-items-start cursor-pointer wrap-in-two-lines fabelle-primary-brown mt-2 ' + (isDesktopOrLaptop ? 'text-center euphemia-400-19' : 'euphemia-400-14 text-left')}
							>
								{/*<LinesEllipsis*/}
								{/*	text={name}*/}
								{/*	maxLine='1'*/}
								{/*	ellipsis='...'*/}
								{/*	trimRight*/}
								{/*	basedOn='words'*/}
								{/*/>*/}

								{name}
							</p>
						</Link>
							<div className={"d-flex justify-content-center align-items-center"}>
								<ProductRating rating={rating} darkTheme={pathname === "/"}/>
							</div>
							{/*<RatingsStars/>*/}

							<div
								className={'d-flex ' + (isDesktopOrLaptop ? 'align-items-center mb-3  justify-content-center' : 'align-items-baseline mb-2  justify-content-left')}>
								<p className={(isDesktopOrLaptop ? 'trade-con-700-24' : 'trade-con-700-16') + ' fabelle-primary-brown whitespace-nowrap mb-0 mr-1'}>
									₹ {price}
								</p>
								{
									parseInt(discountPercentage) > 0 &&
									<del
										className={(isDesktopOrLaptop ? 'trade-con-700-16' : 'trade-con-700-12') + ' fabelle-primary-brown-2 whitespace-nowrap mb-0 mr-1'}>
										{originalPrice}
									</del>
								}
								{
									parseInt(discountPercentage) > 0 &&
									<p className={'trade-con-700-12 fabelle-primary-gold fabelle-brown-background px-2 py-1 mb-0'}>
										{discountPercentage}%Off
									</p>
								}
							</div>

						</>}
					{isDesktopOrLaptop &&
						<>
							{/* desktop rating */}

							{/* desktop qty */}
							{variantId?.length > 0 && isAvailable && <div
								className={'d-flex ' + (isDesktopOrLaptop ? 'justify-content-center' : 'justify-content-between')}>
								<div
									className={'border-box border-radius-0  d-flex justify-content-between align-items-center   w-75 ' + (isDesktopOrLaptop ? ' px-3 py-2' : ' mr-3 px-2 py-1')}>
									<img src={minus} className={isDesktopOrLaptop ? 'icon-24' : 'icon-16'} alt={'alt'}
										 onClick={() => decrementCount()}/>
									<p className={'trade-con-700-24 fabelle-primary-brown mb-0'}>{quantity1}</p>
									<img src={plus} className={isDesktopOrLaptop ? 'icon-24' : 'icon-16'} alt={'alt'}
										 onClick={() => incrementCount()}/>
								</div>
							</div>}

						</>
					}
				</div>

				{/*<RatingsStars/>*/}
				{!isDesktopOrLaptop &&
					<>

						{/*mobile ratings*/}
						<Link to={"/product-detail/" + id}>
							<p style={isDesktopOrLaptop ? {marginBottom: "8px"} : {marginBottom: "10px"}}
							   className={' cursor-pointer fabelle-primary-brown mt-2 ' + (isDesktopOrLaptop ? 'text-center euphemia-400-20' : 'euphemia-400-14 text-left')}
							>
								<LinesEllipsis
									text={name}
									maxLine='2'
									ellipsis='...'
									trimRight
									basedOn='words'
								/>
							</p>
						</Link>
						<div
							className={'d-flex ' + (isDesktopOrLaptop ? 'align-items-center mb-3  justify-content-center' : 'align-items-baseline justify-content-left')}>
							<p className={(isDesktopOrLaptop ? 'trade-con-700-24' : 'trade-con-700-16') + ' fabelle-primary-brown whitespace-nowrap mb-0 mr-1'}>
								₹ {price}
							</p>
							{
								parseInt(discountPercentage) > 0 &&
								<del
									className={(isDesktopOrLaptop ? 'trade-con-700-16' : 'trade-con-700-12') + ' fabelle-primary-brown-2 mb-0 mr-1'}>
									{originalPrice}
								</del>
							}
							{
								parseInt(discountPercentage) > 0 &&
								<p className={'trade-con-700-12 fabelle-primary-gold fabelle-brown-background px-2 py-1 mb-0'}>
									{discountPercentage}%Off
								</p>
							}
						</div>
						<div className={"d-flex  align-items-center"}>
							<ProductRating rating={rating} darkTheme={pathname === "/"}/>
						</div>
						{/*<RatingsStars/>*/}
					</>}

				{/* mobile qty and cart button*/}
				{
					!isDesktopOrLaptop && variantId?.length > 0 && isAvailable ?
						<>
							{
								showAction === true?
									(
										<div className={'d-flex align-items-center'}>
											<div className={'d-flex justify-content-between w-100'}>
												<div style={isDesktopOrLaptop ? {height: '40px'} : {
													height: '32px',
													background: "linear-gradient(90deg, #AD7819 0%, #DEA64A 20.77%, #FFEB95 35.13%, #EFBC68 48.97%, #FFD67A 60.77%, #BE8D3C 74.36%, #9E6C0C 100%)",
													border: "none"
												}}
													 className={'border-box border-radius-0 d-flex justify-content-between align-items-center w-100 ' + (isDesktopOrLaptop ? ' px-3 py-2' : '  px-2 py-0')}>
													<img src={minus} className={'icon-16'} alt={'alt'}
														 onClick={() => decrementCount()}/>
													<p className={'fabelle-primary-brown mb-0 ' + (isDesktopOrLaptop ? 'trade-con-700-24' : 'trade-con-700-16')}>{quantity1}</p>
													<img src={plus} className={'icon-16'} alt={'alt'}
														 onClick={() => incrementCount()}/>
												</div>
											</div>
										</div>
									)
									:
									(
										<CustomButtonSecondary className={'euphemia-700-20 w-100'}
															   clickHandler={addToCartHandler} theme={"light"}
															   name={"Add to cart"}/>
									)
							}
						</>
						:
						<>
							{/*<RatingsStars/>*/}
							{!isDesktopOrLaptop &&
								// <MDBBox className={" justify-content-center align-items-center mb-view-more-bnt"}>
								// 	<CustomButton clickHandler={redirectToDetailPage} theme={"light"} name={"View More"}/>
								// </MDBBox>
								<Link to={"/product-detail/" + id}>
									{/*<div className={'button-ouline-dark'}>*/}
									{/*	<p className={'trade-con-700-16 mb-0 py-2 last-text-center'}>View More</p>*/}
									{/*</div>*/}
									<CustomButtonSecondary className={'euphemia-700-16 w-100'}
														   clickHandler={redirectToDetailPage} theme={"light"}
														   name={"View More"}/>
								</Link>
							}

						</>
				}

				{
					isDesktopOrLaptop &&
					// <p className={'brown-button cursor-pointer mb-0'} onClick={() => addToCart()}>
					// 	Add to cart
					// </p>
					<MDBBox className={"d-flex justify-content-center align-items-center custom-button-wrapper mt-3"}>
						{
							pathname !== "/" ? <>
								{	
									variantId?.length > 0 && isAvailable
									? <CustomButtonSecondary className={'euphemia-700-20 w-100'}
															clickHandler={addToCartHandler} theme={"light"}
															name={"Add to cart"}/> :
									// &&
									<CustomButtonSecondary className={'euphemia-700-20 w-100'}
														clickHandler={redirectToDetailPage} theme={"light"}
														name={"View More"}/>
								}
							</>
							 :
							  <>
								<button className={'d-flex align-items-center justify-content-center w-100 plain-button'} onClick={(args)=>{
									variantId?.length > 0 && isAvailable ?addToCartHandler(args):redirectToDetailPage(args)
								}}>
									<p className='euphemia-700-20 mb-0'>{variantId?.length > 0 && isAvailable ?"Add to Cart":"View More"}</p>
								</button>
							</> 
						 }
					</MDBBox>
				}

			</div>
		</>
	)
}

const mapStateToProps = state => ({
	checkoutIdFromStore: state.CheckoutReducer.checkoutId,
	cartItemFromStore: state.CheckoutReducer.cartItems
})

const mapDispatchToProps = dispatch => ({
	setCartDataToStore: (data) => dispatch(updateCart(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ProductCard)
