import {commitMutation} from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import environment from '../environment'

const mutation = graphql`
  mutation CheckoutLineAddMutation($checkoutId:String!,$quantity:Int!,$variantId:String!) {
	checkoutLineAdd(checkoutId:$checkoutId,quantity:$quantity,variantId:$variantId) {
		checkout {
	        id
	        cartItems {
				edges {
					node {
						id
						quantity
						variant {
							id
							getPrice
							getOriginalPrice
							featuredImage
							discountedPrice
							discountPercentage
							sku
							isAvailable
							variantId
							product{
								rating
								name
								description
								price
								discountedPrice
								collection{
									name
								}
							}
						}
					}
            	}
			}
			totalAmount
			discount
			voucherCode
			getActualTotal
			offerDiscount
			shippingPrice
			totalWithShipping
			confettiAmount
			user {
				username
			}
		}
	}    
}
`

export default (data, callback, errCallback) => {
	const variables = {
		checkoutId: data.checkoutId,
		quantity: data.quantity,
		variantId:data.variantId
	};

	commitMutation(
		environment,
		{
			mutation,
			variables,

			onCompleted: (response, err) => {

				if (response.checkoutLineAdd !== null) {
					callback(response)
				} else {
					errCallback(err[0].message);
				}

			},
			onError: err => {

				errCallback(err);
			},
		},
	)
}






