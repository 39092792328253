import graphql from "babel-plugin-relay/macro";

const CategoryQuery=graphql`

    query CategoryQuery{
		category {
            edges {
                node {
                 id
                 name    
                 backgroundImage  
                 categoryIcon    
                }
            }
		}
	}
`

export default CategoryQuery
