import React, {useRef} from 'react'
import './DesktopCartListComponent.scss'
import '../../assets/common/common_style.scss'

import deleteIcon from "../../assets/icons/DeleteIcon.svg";
import minus from "../../assets/icons/minus-icon.svg";
import minusMobile from "../../assets/icons/minus-icon.svg";
import plus from "../../assets/icons/Plus-icon.svg";
import plusMobile from "../../assets/icons/Plus-icon.svg";
import {useMediaQuery} from "react-responsive";
import ClearCheckoutMutation from "../../mutations/ClearCheckoutMutation";
import {addDataToCheckout, setCartItem, setCheckoutId} from "../../store/actions/CheckoutActions";
import {connect} from "react-redux";
import FeaturedProductImg from "../../assets/images/fabelle-detailchocolate-10-1.png";

const DesktopCartListComponent = (props) => {
	const {
		item,
		count,
		setCount, removeElement
	} = props
	const isDesktopOrLaptop = useMediaQuery({minWidth: 1025})

	const imageEl = useRef(null);


	return (
		<div className={'desktop-cart-list-component'} key={item.node && item.node.variant && item.node.variant.id}>
			<div>
				<div
					className={'desktop-product-listing-wrap my-4'}>
					<div className={'desktop-product-listing d-flex'}>
						<img src={item.node && item.node.variant && item.node.variant.featuredImage?item.node.variant.featuredImage:FeaturedProductImg}
							 ref={imageEl}
							 // onError={() => {
								//  imageEl.current.src = FeaturedProductImg;
							 // }}
						     className={'product-image mr-4'} alt={'alt'}/>
						<div className={' w-100 d-flex flex-column justify-content-between'}>
							<div className={'d-flex justify-content-between w-100'}>
								<p className={'w-75 euphemia-700-16 fabelle-primary-brown mb-4'}>{item.node && item.node.variant && item.node.variant.product.name}
									{/*,{item.description}*/}
								</p>
								<img src={deleteIcon} className={'icon-24'} alt={'alt'} onClick={removeElement}/>
							</div>
							<div className={'d-flex  justify-content-between'}>
								<div
									className={'border-box border-radius-0  d-flex justify-content-between align-items-center   w-100 ' + (isDesktopOrLaptop ? ' p-2' : ' mr-3 px-2 py-1')}>
									<img src={isDesktopOrLaptop ? minus : minusMobile}
									     className={isDesktopOrLaptop ? 'icon-24' : 'icon-16'}
									     alt={'alt'}
									     onClick={() => count !== 0 && setCount(count - 1)}/>
									<p className={'fabelle-primary-brown mb-0 ' + (isDesktopOrLaptop ? 'trade-con-700-20' : 'trade-con-700-16')}>{count}</p>
									<img src={isDesktopOrLaptop ? plus : plusMobile}
									     className={isDesktopOrLaptop ? 'icon-24' : 'icon-16'}
									     alt={'alt'}
									     onClick={() => setCount(count + 1)}/>
								</div>
								<div className={'d-flex'}>
									{/*<p className={'trade-con-700-24 fabelle-primary-white mr-2 mb-0'}>*/}
									{/*	{count} x*/}
									{/*</p>*/}
									<p className={'trade-con-700-24 fabelle-primary-brown mb-0'}>
										₹{item.node && item.node.variant && parseInt(item.node.variant.getPrice) * count}
									</p>
								</div>
							</div>
						</div>

					</div>
				</div>
				<div className={'d-flex justify-content-center'}>
					<hr className={'divder-line'}/>
				</div>
			</div>

		</div>
	)
}

const mapStateToProps = state => ({
	checkoutIdFromStore: state.CheckoutReducer.checkoutId,
})

const mapDispatchToProps = dispatch => ({
	setCheckoutIdToStore: (data) => dispatch(setCheckoutId(data)),
	setCheckoutDataToStore: (data) => dispatch(addDataToCheckout(data)),
	setCheckoutCartItemToStore: (data) => dispatch(setCartItem(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(DesktopCartListComponent)
