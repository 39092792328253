import {commitMutation} from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import environment from '../environment'

const mutation = graphql`
  mutation ProductByFilterMutation(
		$categories:[String]
		$attributeValues:[String]
		$orderBy:OrderByEnum
		$searchTerm:String
		$collectionId:String
  ) {
	productVariantFilter(
	    categories:$categories
		attributeValues:$attributeValues
		orderBy:$orderBy,
		searchTerm:$searchTerm
		collectionId:$collectionId
) {
    status
    productVariants {
    edges {
        node {
            id
            sku
            variantId
            isAvailable
            getPrice
            getOriginalPrice
            featuredImage
            discountedPrice
            discountPercentage
            isInWishlist
            product {
                id
                name
                rating
                description
                price
                weight
                discountedPrice
                masterSku
                featuredImage            
                collection{
                	name
                }
                

            }
        }
    }
}
    }
  }
`

export default (data, callback, errCallback) => {
	const variables = {
		categories: data.categories,
		attributeValues: data.attributeValues,
		orderBy: data.orderBySort,
		searchTerm:data.searchTerm,
		collectionId:data.collectionId

	};

	commitMutation(
		environment,
		{
			mutation,
			variables,

			onCompleted: (response, err) => {

				if (response.productVariantFilter !== null) {
					callback(response)
				} else {
					errCallback(err[0].message);
				}

			},
			onError: err => {

				errCallback(err);
			},
		},
	)
}




// category {
//     id
//     name
// }

