import SolidStar from "../../assets/icons/star-solid.svg";
import EmptyStar from "../../assets/icons/star-regular.svg";
import HalfStar from "../../assets/icons/star-half-stroke-solid.svg";
import SolidDarkStar from "../../assets/icons/brown-star-rating.svg";
import {Rating} from "react-simple-star-rating";
import React from "react";
import "./ProductRating.scss";

const ProductRating = (props) => {
    const {rating} = props;
    return(
       <div className={"product-rating-component"}>
           <Rating
               initialValue={rating}
               iconsCount={5}
               readonly={true}
               allowFraction={true}
               className={"custom-product-rating"}
               fillIcon={<img src={props.darkTheme ?SolidDarkStar:SolidStar} alt={""} style={{width:"15px",height:"15px"}} />}
               emptyIcon={<img src={props.darkTheme ?SolidStar:EmptyStar} alt={""} style={{width:"15px",height:"15px"}}/>}
               halfIcon={<img src={HalfStar} alt={""} style={{width:"15px",height:"15px"}}/>}
           />
       </div>
    )
}
export default ProductRating;