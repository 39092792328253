import React, {useEffect, useState} from 'react'
import './FooterComponent.scss'
import '../../assets/common/common_style.scss'
import {useNavigate} from "react-router";
import {useMediaQuery} from "react-responsive";

import logo from '../../assets/images/logo.png'
import mobile_insta from '../../assets/icons/instagram.svg'
import mobile_twit from '../../assets/icons/color.svg'
import mobile_fb from '../../assets/icons/facebook.svg'
import mobile_linkin from '../../assets/images/linkedIn.svg'
import mobile_youtube from '../../assets/icons/youtube.svg'

import InputComponent from "../InputComponent/InputComponent";
import {
	fabelle_chocolates,
	fabelle_footer_all, fabelle_footer_information,
	fabelle_footer_more,
	fabelle_footer_pages, fabelle_footer_pages_mobile, fabelle_footer_pages_mobile_2,
	fabelle_pages
} from "../../utils/constants";
import uuid from "react-uuid";
import {fetchQuery} from "relay-runtime";
import environment from "../../environment";
import CategoryQuery from "../../queries/CategoryQuery";
import CollectionQuery from "../../queries/CollectionQuery";
import {useLocation} from "react-router-dom";
import {CustomToast} from "../CustomToast/CustomToast";
import cogoToast from "cogo-toast";
import EmailSubscriptionMutation from "../../mutations/EmailSubscriptionMutation";
import {store} from "../../index";
import {setCartModalState} from "../../store/actions/CheckoutActions";
import FSSAILogo from "../../assets/images/fssai-logo.svg";

const FooterComponent = (props) => {
	let navigate = useNavigate()
	const isDesktopOrLaptop = useMediaQuery({minWidth: 1024})
	let params = useLocation().pathname.split('/')

	const [chocolateTypesList, setChocolateTypesList] = useState([]);
	const [collectionList, setCollectionList] = useState([]);
	const [getgiftHampersID, setGiftHampersID] = useState('')
	const [emailId, setEmailId] = useState("");

	useEffect(() => {
		// console.log("footer")
		categoryFetch()
		collectionFetch();
	}, []);

	useEffect(() => {
		const temp = collectionList.filter(item => item.name === 'Gift Hampers')
		setGiftHampersID(temp[0] && temp[0].id)
	}, [collectionList])

	const collectionFetch = () => {
		fetchQuery(environment, CollectionQuery)
			.toPromise()
			.then(response => {
				if (response.collections !== null) {
					setCollectionList(response.collections)
				} else {
					setCollectionList([]);
				}
			}, err => {
				console.log(err)
				setCollectionList([]);
			});
	}

	const categoryFetch = () => {
		fetchQuery(environment, CategoryQuery)
			.toPromise()
			.then(response => {
				if (response.category !== null && response.category.edges.length > 0) {
					// debugger
					let ChocolateTypes = response.category.edges.filter(function (category) {
						let category_name = category.node.name.toLowerCase();
						return !(category_name.includes("cakes") || category_name.includes("beverages"));
					});
					let tempArr = []
					let tempObj
					ChocolateTypes.filter(filter => {
						// debugger
						let chocolateInFooter = fabelle_chocolates
						if (chocolateInFooter.includes(filter.node.name.toLowerCase())) {
							tempObj = {
								page: filter.node.name,
								route: `/product-list/category/${filter.node.id}`
							}
							tempArr.push(tempObj)
						}
					})
					setChocolateTypesList(tempArr);

				} else {
					setChocolateTypesList([]);

				}
			}, err => {
				console.log(err)
				setChocolateTypesList([]);

			});
	}

	const newsletterHandler_onChange = (e) => {
		setEmailId(e.target.value)
	}
	const newsletterHandler_onSubmit = () => {
		let data = {
			email: emailId,
		}
		// debugger

		if (emailId.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
			EmailSubscriptionMutation(data, (response) => {
				if (response.subscribeByEmail.ok) {
					setEmailId("")
					cogoToast.error(<CustomToast type={"success"}
					                             message={"Thank You! Will notify you with further updates"}/>, {position: "top-center"});
				} else {
					setEmailId("")

					cogoToast.error(<CustomToast type={"info"}
					                             message={response.subscribeByEmail.message}/>, {position: "top-center"});
				}
			}, (err) => {
				console.log(err)
				cogoToast.error(<CustomToast type={"error"}
				                             message={err?.message}/>, {position: "top-center"});
			})
		} else {
			cogoToast.error(<CustomToast type={"error"}
			                             message={"Please enter valid Email ID"}/>, {position: "top-center"});
		}
	}

	const redirectHandler = (route) => {
		if (route === "cart-modal") {
			store.dispatch(setCartModalState(true))
		} else {
			window.scrollTo(0, 0);
			navigate(route, false)
		}
	}

	return (
		<>
			<div className={'footer-component'}>
				{
					isDesktopOrLaptop ?
						<div className={'row'}>
							<div className={'col-4'}>
								<p className={'euphemia-700-20 text-capitalize fabelle-primary-white'}
									style={{marginBottom: '24px'}}>
										My Account
								</p>
								<p  onClick={() => redirectHandler('/track-order')}
									className={'euphemia-400-20 cursor-pointer'}
									style={{color: '#C9C5CA',marginBottom: '16px',width:"fit-content"}}
								>
										My Orders
								</p>
								<div className={'mt-5'} style={{width:'fit-content'}}>
									<img src={logo} className={'mb-4'} alt={'alt'} style={{maxWidth:'254px'}}/>
									<div className={'d-flex gap-32 justify-content-between align-items-center'}>
										<a className={'no-style'} href={"https://www.facebook.com/FabelleChocolates/"}
										target={'_blank'} rel="noopener noreferrer"><img
											src={mobile_fb} alt={'alt'} className={'icon-24'}/></a>
										<a className={'no-style'}
										href={"https://instagram.com/fabellechocolates?igshid=YmMyMTA2M2Y="}
										target={'_blank'} rel="noopener noreferrer"><img
											src={mobile_insta} alt={'alt'} className={'icon-24'}/></a>
										<a className={'no-style'}
										href={"https://twitter.com/Fabelle?t=bsXEgAZuNXc9HURrzjg08w&s=09"} target={'_blank'} rel="noopener noreferrer"><img
											src={mobile_twit}
											alt={'alt'}
											className={'icon-24'}/></a>
										<a className={'no-style'} href={"https://youtube.com/c/FabelleExquisiteChocolates"}
										target={'_blank'} rel="noopener noreferrer"><img
											src={mobile_youtube} alt={'alt'} className={'icon-24'}/></a>
									</div>
								</div>							
							</div>
							<div className={'col-4'}>
								<p className={'euphemia-700-20 text-capitalize fabelle-primary-white'}
									style={{marginBottom: '24px'}}>
										Miscelleneous
								</p>								
								{
									fabelle_footer_information.map(item => {
										let itemRoute = item.route;
										return (<p 
													key={uuid()} 
													onClick={() => redirectHandler(itemRoute)}
													className={'euphemia-400-20 cursor-pointer'}
													style={{color: '#C9C5CA',marginBottom: '16px',width:"fit-content"}}
												>
													{item.page}
												</p>)
									})
								}
								<img src={FSSAILogo} alt={""} className={'mt-5'}/>
							</div>
							<div className={'col-4'}>
								<p className={'euphemia-700-20 text-capitalize fabelle-primary-white'}
									style={{marginBottom: '24px'}}>
										Quick Links
								</p>
								<div className={'row'}>
									<div className='col-6'>
										{
											fabelle_footer_pages.slice(0,4).map(item => {
												let itemRoute = item.route
												return (<p 
															key={uuid()} 
															onClick={() => redirectHandler(itemRoute)}
															className={'euphemia-400-20 cursor-pointer'}
															style={{color: '#C9C5CA',marginBottom: '16px',width:"fit-content"}}
														>
															{item.page}
														</p>)
											})
										}
									</div>
									<div className='col-6'>
										{
											fabelle_footer_pages.slice(4,fabelle_footer_pages.length).map(item => {
												let itemRoute = item.route
												return (<p 
															key={uuid()} 
															onClick={() => redirectHandler(itemRoute)}
															className={'euphemia-400-20 cursor-pointer'}
															style={{color: '#C9C5CA',marginBottom: '16px',width:"fit-content"}}
														>
															{item.page}
														</p>)
											})
										}
										<a href={"https://www.itcportal.com/"} target={"_blank"} rel="noopener noreferrer">
											<p className={'euphemia-400-20 cursor-pointer'}
												style={{color: '#C9C5CA',marginBottom: '16px',width:"fit-content"}}
											>
												ITC Portal
											</p>
										</a>
									</div>
								</div>
								<div className={'mt-5'}>
									<p className={'euphemia-700-12 fabelle-primary-gold mb-3'}>For Newsletter Subscription</p>	
									<InputComponent 
										onChangeHandler={(e) => newsletterHandler_onChange(e)}
										value={emailId}
										clickHandler={newsletterHandler_onSubmit}
										placeholder={'Enter your mail address'}
									/>
								</div>
							</div>
						</div>
						:
						<div
							className={'footer-content-wrapper mobile-horziontal-padding'}>
							<img src={logo} className={'mb-4'} alt={'alt'} style={{width:'50%'}}/>					
							<div className='mb-4'>
								<p className={'euphemia-400-20 text-capitalize fabelle-primary-white'}
									style={{marginBottom: '8px'}}>
										Quick Links
								</p>
								<div className={'row'}>
									<div className='col-6'>
										{
											fabelle_footer_pages.slice(0,4).map(item => {
												let itemRoute = item.route
												return (<p 
															key={uuid()} 
															onClick={() => redirectHandler(itemRoute)}
															className={'euphemia-400-14 cursor-pointer'}
															style={{color: '#C9C5CA',marginBottom: '16px',width:"fit-content"}}
														>
															{item.page}
														</p>)
											})
										}
									</div>
									<div className='col-6'>
										{
											fabelle_footer_pages.slice(4,fabelle_footer_pages.length).map(item => {
												let itemRoute = item.route
												return (<p 
															key={uuid()} 
															onClick={() => redirectHandler(itemRoute)}
															className={'euphemia-400-14 cursor-pointer'}
															style={{color: '#C9C5CA',marginBottom: '16px',width:"fit-content"}}
														>
															{item.page}
														</p>)
											})
										}
										<a href={"https://www.itcportal.com/"} target={"_blank"} rel="noopener noreferrer">
											<p className={'euphemia-400-14 cursor-pointer'}
												style={{color: '#C9C5CA',marginBottom: '16px',width:"fit-content"}}
											>
												ITC Portal
											</p>
										</a>
									</div>
								</div>
							</div>
							<div className={'mb-4 row'}>
								<div className={'col-6'}>
									<p className={'euphemia-400-20 text-capitalize fabelle-primary-white'}
										style={{marginBottom: '8px'}}>
											Miscelleneous
									</p>								
									{
										fabelle_footer_information.map(item => {
											let itemRoute = item.route;
											return (<p 
														key={uuid()} 
														onClick={() => redirectHandler(itemRoute)}
														className={'euphemia-400-14 cursor-pointer'}
														style={{color: '#C9C5CA',marginBottom: '16px',width:"fit-content"}}
													>
														{item.page}
													</p>)
										})
									}
								</div>
								<div className={'col-6'}>
									<p className={'euphemia-400-20 text-capitalize fabelle-primary-white'}
										style={{marginBottom: '8px'}}>
											My Account
									</p>
									<p  onClick={() => redirectHandler('/track-order')}
										className={'euphemia-400-14 cursor-pointer'}
										style={{color: '#C9C5CA',marginBottom: '16px',width:"fit-content"}}
									>
											My Orders
									</p>

									<div className={'d-flex gap-1 justify-content-between align-items-center mt-4'}>
										<a className={'no-style'} href={"https://www.facebook.com/FabelleChocolates/"}
										target={'_blank'} rel="noopener noreferrer"><img
											src={mobile_fb} alt={'alt'} className={'icon-24'}/></a>
										<a className={'no-style'}
										href={"https://instagram.com/fabellechocolates?igshid=YmMyMTA2M2Y="}
										target={'_blank'} rel="noopener noreferrer"><img
											src={mobile_insta} alt={'alt'} className={'icon-24'}/></a>
										<a className={'no-style'}
										href={"https://twitter.com/Fabelle?t=bsXEgAZuNXc9HURrzjg08w&s=09"} target={'_blank'} rel="noopener noreferrer"><img
											src={mobile_twit}
											alt={'alt'}
											className={'icon-24'}/></a>
										<a className={'no-style'} href={"https://youtube.com/c/FabelleExquisiteChocolates"}
										target={'_blank'} rel="noopener noreferrer"><img
											src={mobile_youtube} alt={'alt'} className={'icon-24'}/></a>
									</div>
								</div>
							</div>
							<div className={'mb-5'}>
								<p className={'euphemia-700-14 fabelle-primary-gold text-center'}
								style={{marginBottom: "8px"}}>Newsletter
									Subscription
								</p>
								<InputComponent 
									onChangeHandler={(e) => newsletterHandler_onChange(e)}
									value={emailId}
									className={'footer-input-mobile'}
									clickHandler={newsletterHandler_onSubmit}
									placeholder={'Enter your mail address'}/>
							</div>
							<div className={'text-center mb-4'}>
								<img src={FSSAILogo} alt={""}/>
							</div>
						</div>
				}			
			</div>
			<div className={'copy-rights-section'}>
				Copyright © {new Date().getFullYear()} ITC Ltd. All rights reserved.
			</div>
		</>
	)
}

export default FooterComponent
