import {
	ACTIVE_SINGLE_PRODUCT_ID,
	ACTIVE_SINGLE_PRODUCT_DATA,
	CATEGORY_LIST,
	SET_SEARCH_TERM,
	SET_SEARCHED_FROM_HOME
} from "../actions";

const initialState = {
	activeSingleProduct_id: "",
	activeSingleProduct_data: {},
	categoryList: [],
	searchTerm: "",
	searchedFromHome: false
}

const ProductReducer = (state = initialState, action) => {
	switch (action.type) {

		case ACTIVE_SINGLE_PRODUCT_ID:
			return {
				...state,
				activeSingleProduct_id: action.payload.data
			}
		case ACTIVE_SINGLE_PRODUCT_DATA:
			return {
				...state,
				activeSingleProduct_data: action.payload.data
			}
		case CATEGORY_LIST:
			return {
				...state,
				categoryList: action.payload.data
			}
		case SET_SEARCH_TERM:
			return {
				...state,
				searchTerm: action.payload.data
			}
		case SET_SEARCHED_FROM_HOME:
			return {
				...state,
				searchedFromHome: action.payload.data
			}

		default:
			return state
	}
}

export default ProductReducer