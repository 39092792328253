import React, { useState } from "react";
import uuid from "react-uuid";
import DesktopCartListComponent from "../DesktopCartListComponent/DesktopCartListComponent";
import ProductCardHorizontal from "../ProductCardHorizontal/ProductCardHorizontal";
import CustomButtonPrimary from "../CustomButton/CustomButtonPrimary";
import { sendAddToCartToGA, sendRemoveFromCartToGA } from "../../utils/GA-Events";
import ClearCheckoutMutation from "../../mutations/ClearCheckoutMutation";
import { connect } from "react-redux";
import { useMediaQuery } from "react-responsive";
import CheckoutLineAddMutation from "../../mutations/CheckoutLineAddMutation";
import cogoToast from "cogo-toast";
import { CustomToast } from "../CustomToast/CustomToast";
import CheckoutLineDeleteMutation from "../../mutations/CheckoutLineDeleteMutation";
import { useNavigate } from "react-router";
import { setCartModalState, updateCart
  // , updateCouponValue 
} from "../../store/actions/CheckoutActions";
import FeaturedProductImg from "../../assets/images/fabelle-detailchocolate-10-1.png";


const CartProductListing=(props)=>{
  const { cartDetailsFromStore,checkoutIdFromStore,closeModal,setCartDataToStore,disableAddToCart
} = props;
  const isDesktopOrLaptop = useMediaQuery({ minWidth: 1024 });
  const [productsInCart, setProductsInCart] = useState(cartDetailsFromStore);

  let navigate = useNavigate();

  const addItemToCart = (quantity, lineItem) => {
    let variable = {
      checkoutId: checkoutIdFromStore,
      quantity: quantity === 0 ? 1 : quantity,
      variantId: lineItem.variant.id,
    };
    CheckoutLineAddMutation(
      variable,
      (response) => {
        if (response.checkoutLineAdd !== null) {          
                      let cartObj = {
              checkoutId: response.checkoutLineAdd.checkout.id,
              checkout: response.checkoutLineAdd.checkout,
              cartItems: response.checkoutLineAdd.checkout.cartItems.edges,
            };
            setCartDataToStore(cartObj);
            addToCartGA(lineItem, quantity);
        } else {
          cogoToast.error(
            <CustomToast type={"error"} message={"Something went wrong"} />,
            { position: "top-center" }
          );
        }
      },
      (err) => {
        cogoToast.error(<CustomToast type={"error"} message={err?.message} />, {
          position: "top-center",
        });
      }
    );
  };

  const addToCartGA = (lineItem, quantity) => {
    let tmp_variant = lineItem.variant;
    let product_formate = {
      sku: tmp_variant.sku,
      name: tmp_variant.product.name,
      collection:
        tmp_variant.product && tmp_variant.product.collection
          ? tmp_variant.product.collection.name
          : "",
      price: tmp_variant.getPrice,
      quantity: quantity === 0 ? 1 : quantity,
    };
    sendAddToCartToGA(product_formate);
  };

  const removeFromCartGA = (lineItem) => {
    let tmp_variant = lineItem.variant;
    let product_formate = {
      sku: tmp_variant.sku,
      name: tmp_variant.product.name,
      collection:
        tmp_variant.product && tmp_variant.product.collection
          ? tmp_variant.product.collection.name
          : "",
      price: tmp_variant.getPrice,
      quantity: lineItem.quantity,
    };
    sendRemoveFromCartToGA(product_formate);
  };

  const clearCartItem = () => {
    let variable = {
      checkoutId: checkoutIdFromStore,
    };

    ClearCheckoutMutation(
      variable,
      (response) => {
        if (response.checkoutClear !== null) {
          let cartObj = {
            checkoutId: response.checkoutClear.checkout.id,
            checkout: response.checkoutClear.checkout,
            cartItems: response.checkoutClear.checkout.cartItems.edges,
          };
          setCartDataToStore(cartObj);
          cogoToast.success(
            <CustomToast type={"success"} message={"Cart Cleared"} />,
            { position: "top-center" }
          );
        }
      },
      (err) => {
        cogoToast.error(<CustomToast type={"error"} message={err?.message} />, {
          position: "top-center",
        });
      }
    );
  };

  const removeItemFromCart = (lineItem) => {
    let variable = {
      checkoutId: checkoutIdFromStore,
      lineId: lineItem.id,
    };
    CheckoutLineDeleteMutation(
      variable,
      (response) => {
        if (response.checkoutLineDelete !== null) {
              let cartObj = {
                checkoutId: response.checkoutLineDelete.checkout.id,
                checkout: response.checkoutLineDelete.checkout,
                cartItems: response.checkoutLineDelete.checkout.cartItems.edges,
              };
              setCartDataToStore(cartObj);
              removeFromCartGA(lineItem);
        } else {
          cogoToast.error(
            <CustomToast type={"error"} message={"Something went wrong"} />,
            { position: "top-center" }
          );
        }
      },
      (err) => {
        cogoToast.error(<CustomToast type={"error"} message={err?.message} />, {
          position: "top-center",
        });
      }
    );
  };

  const changeQuantity = (quant, lineItem) => {
    addItemToCart(quant, lineItem);
  };

  const addProductRedirectHanlder = () => {
    closeModal();
    navigate("/product-list", { replace: false });
  };
  console.log(cartDetailsFromStore)
  return (
    <div className="cart-product-listing-component">
      {isDesktopOrLaptop ? (
        cartDetailsFromStore.cartItems &&
        cartDetailsFromStore.cartItems.edges.map((item, index) => {
          return (
            <React.Fragment key={uuid()}>
              <DesktopCartListComponent
                item={item}
                count={item.node.quantity}
                removeElement={() => removeItemFromCart(item.node)}
                setCount={(args) => changeQuantity(args, item.node)}
              />
            </React.Fragment>
          );
        })
      ) : (
        <div className={""}>
          {cartDetailsFromStore.cartItems &&
            cartDetailsFromStore.cartItems.edges.map((item, index) => {
              return (
                <div
                  key={item}
                  className={
                    productsInCart.length === index + 1 ? "mb-0" : "mb-3"
                  }
                >
                  <ProductCardHorizontal
                    id={item.node.variant.id}
                    rating={item.node.variant.product.rating}
                    variantId={item.node.variant.variantId}
                    isAvailable={item.node.variant.isAvailable}
                    featuredImage={
                      item.node.variant.featuredImage
                        ? item.node.variant.featuredImage
                        : FeaturedProductImg
                    }
                    name={item.node.variant.product.name}
                    description={item.node.variant.product.description}
                    price={item.node.variant.getPrice}
                    variantPricePincodeBased={item.node.variantPricePincodeBased || null}
                    originalPrice={item.node.variant.getOriginalPrice}
                    offer={"0"}
                    showAlert={true}
                    discountedPrice={item.node.variant.discountedPrice}
                    discountPercentage={item.node.variant.discountPercentage}
                    productData={item.node.variant}
                    removeElement={() => removeItemFromCart(item.node)}
                    quantity={item.node.quantity}
                    disableAddToCart={disableAddToCart}
                    
                    // setRefreshCount={args => setRefreshCount(args)}
                    // refreshCount={refreshCount}
                  />
                </div>
              );
            })}
        </div>
      )}
      {cartDetailsFromStore.cartItems &&
        cartDetailsFromStore.cartItems.edges.length === 0 && (
          <div>
            <div
              className={
                "w-100 d-flex flex-column align-items-center justify-content-center p-3"
              }
            >
              <p className={"euphemia-700-16 mb-4 fabelle-primary-brown "}>
                Your cart is Empty
              </p>
              <p
                className={
                  "euphemia-400-16 mb-2 fabelle-primary-brown text-center mb-5"
                }
                style={{ opacity: "0.5" }}
              >
                Looks like you have not added any products to the cart. Go ahead
                and explore Fabelle chocolates
              </p>
              {/*{*/}
              {/*	!isDesktopOrLaptop ?*/}
              <div className={"w-100 d-flex justify-content-center"}>
                <CustomButtonPrimary
                  theme={"light"}
                  reduceWidth={true}
                  className={"euphemia-700-16 "}
                  name={"Add Products"}
                  clickHandler={addProductRedirectHanlder}
                />
              </div>

              {/*:*/}
              {/*		null*/}
              {/*}*/}
            </div>
          </div>
        )}
    </div>
  );
}
const mapStateToProps = (state) => ({
    cartDetailsFromStore: state.CheckoutReducer.checkoutData,
    checkoutIdFromStore: state.CheckoutReducer.checkoutId,
      });
  
  const mapDispatchToProps = (dispatch) => ({
    setCartModalStatusToStore: (data) => dispatch(setCartModalState(data)),
    setCartDataToStore: (data) => dispatch(updateCart(data))
  });
  
export default connect(mapStateToProps, mapDispatchToProps)(CartProductListing);
