import React from 'react'
import './CustomButtonPrimary.scss'

const CustomButtonPrimary = (props) => {
	const {theme, name, clickHandler, disabled, className, reduceWidth} = props;
	return (
		<div
			className={"custom-button-primary  " + (disabled ? "opacity-60 " : "opacity-100 ") + (reduceWidth === true ? " w-fit-content " : " w-100 ")}>
			<button type="button" disabled={disabled} className={`custom-rich-btn ${theme}` + ` ${className}`}
			        onClick={clickHandler}>
				<p className={'mb-2 whitespace-nowrap'}> {name}</p>
			</button>
		</div>

	)
}

export default CustomButtonPrimary
