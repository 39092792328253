import {commitMutation} from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import environment from '../environment'

const mutation = graphql`
  mutation CheckServiceAvailablityMutation(
        $pinCode: String!
  ) {
	checkServiceAvailability(
	    pinCode:$pinCode 
) {
    available
    message
    }
  }
`

export default (data, callback, errCallback) => {
	const variables = {
		pinCode: data.pinCode,

	};

	commitMutation(
		environment,
		{
			mutation,
			variables,

			onCompleted: (response, err) => {

				if (response.checkServiceAvailability !== null) {
					callback(response)
				} else {
					errCallback(err[0].message);
				}

			},
			onError: err => {
			
				errCallback(err);
			},
		},
	)
}






