import "./LoadingComponent.scss";
import loadingCircle from "../../assets/icons/loading-circle.svg";
import React from "react";

const LoadingComponent = () => {
    return(
        <div className={"custom-loading-component"}>
            <p className={"mb-0 loading-title"}>
                Loading..
            </p>
            <img
                src={loadingCircle}
                className={"loading-asset"}
                alt={"loading"}
            />
        </div>
    )
}

export default LoadingComponent;