import {
	ADD_TO_CART,
	CART_ITEM,
	CART_MODAL, ORDER_DETAILS, PAYMENT_DETAILS,
	SET_BILLING_ADDRESS,
	SET_CHECKOUT_ID,
	SET_EMAIL,
	SET_SHIPPING_ADDRESS,CLEARCART,UPDATE_CART,COUPON_VALUE
} from "../actions";

const initialState = {
	checkoutId: "",
	checkoutData: {},
	isCartOpen: false,
	cartItems: [],
	email: "",
	shippingAddress: {},
	billingAddress: {},
	orderDetails: {},
	paymentDetails: {},
	couponValue:null,
	promoCode:""
}

const CheckoutReducer = (state = initialState, action) => {
	switch (action.type) {

		case SET_CHECKOUT_ID:
			return {
				...state,
				checkoutId: action.payload.data
			}

		case ADD_TO_CART:
			return {
				...state,
				checkoutData: action.payload.data
			}
		case UPDATE_CART:
			let couponData = {
				couponValue:null,
				promoCode:""
			}
			if(action.payload.data.checkout.discount !== 0) {
				couponData.couponValue = action.payload.data.checkout.discount;
				couponData.promoCode = action.payload.data.checkout.voucherCode;
			}
			return {
				...state,
				...couponData,
				checkoutId:action.payload.data.checkoutId,
				checkoutData: action.payload.data.checkout,
				cartItems: action.payload.data.cartItems,
				email: "",
				shippingAddress: {},
				billingAddress: {},
				orderDetails: {},
				paymentDetails: {},
			}

		case CART_MODAL:
			return {
				...state,
				isCartOpen: action.payload.data
			}
		case CART_ITEM:
			return {
				...state,
				cartItems: action.payload.data
			}
		case SET_EMAIL:
			return {
				...state,
				email: action.payload.data
			}
		case SET_SHIPPING_ADDRESS:
			return {
				...state,
				shippingAddress: action.payload.data
			}
		case SET_BILLING_ADDRESS:
			return {
				...state,
				billingAddress: action.payload.data
			}
		case ORDER_DETAILS:
			return {
				...state,
				orderDetails: action.payload.data
			}
		case PAYMENT_DETAILS:
			return {
				...state,
				paymentDetails: action.payload.data
			}
		case COUPON_VALUE:
			return{
				...state,
				couponValue:action.payload.couponValue,
				promoCode: action.payload.promoCode
			}
		case CLEARCART:
			return {
				checkoutId: "",
				checkoutData: {},
				isCartOpen: false,
				cartItems: [],
				email: "",
				shippingAddress: {},
				billingAddress: {},
				orderDetails: {},
				paymentDetails: {},
				couponValue: null,
				promoCode: ""
			}

		default:
			return state
	}
}

export default CheckoutReducer
