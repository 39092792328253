//product actions
export const ACTIVE_SINGLE_PRODUCT_ID = 'ACTIVE_SINGLE_PRODUCT_ID'
export const ACTIVE_SINGLE_PRODUCT_DATA = 'ACTIVE_SINGLE_PRODUCT_DATA'
export const CATEGORY_LIST = 'CATEGORY_LIST'
export const SET_SEARCH_TERM = 'SET_SEARCH_TERM'
export const SET_SEARCHED_FROM_HOME = 'SET_SEARCHED_FROM_HOME'

//checkout actions
export const SET_CHECKOUT_ID = 'SET_CHECKOUT_ID'
export const ADD_TO_CART = 'ADD_TO_CART'
export const CART_MODAL = 'CART_MODAL'
export const CART_ITEM = 'CART_ITEM'
export const SET_EMAIL = 'SET_EMAIL'
export const SET_SHIPPING_ADDRESS = 'SET_SHIPPING_ADDRESS'
export const SET_BILLING_ADDRESS = 'SET_BILLING_ADDRESS'
export const ORDER_DETAILS = 'ORDER_DETAILS'
export const PAYMENT_DETAILS = 'PAYMENT_DETAILS'
export const CLEARCART = 'CLEARCART';
export const UPDATE_CART = 'UPDATE_CART';
export const COUPON_VALUE = 'COUPON_VALUE';

// auth actions
export const LOGIN_MODAL = "LOGIN_MODAL";
export const MOBILE_NUMBER = "MOBILE_NUMBER";
export const SET_USER_DETAIL = "SET_USER_DETAIL";

export const RESET_STORE = 'RESET_STORE';

