import {commitMutation} from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import environment from '../environment'

const mutation = graphql`
  mutation RemovePromoCodeMutation(
        $checkoutId: String!
  ) {
	removePromoCode(
	    checkoutId:$checkoutId  
) {
    checkout {
	    id
	    cartItems {
			edges {
				node {
					id
					quantity
					variant {
						id
						getPrice
						getOriginalPrice
						featuredImage
						discountedPrice
						discountPercentage
						sku
						isAvailable
						variantId
						product{
							rating
							name
							description
							price
							discountedPrice
							collection{
								name
							}
						}
					}
					variantPricePincodeBased
				}
			}
		}
		totalAmount
		discount
		offerDiscount
		voucherCode
		getActualTotal
		shippingPrice
		totalWithShipping
		confettiAmount
	    user {
	        username
	    }
	}
    message
    }
  }
`

export default (data, callback, errCallback) => {
	const variables = {
		checkoutId:data.checkoutId,

	};

	commitMutation(
		environment,
		{
			mutation,
			variables,

			onCompleted: (response, err) => {

				if (response.removePromoCode !== null) {
					callback(response)
				} else {
					errCallback(err[0].message);
				}

			},
			onError: err => {

				errCallback(err);
			},
		},
	)
}






