import {SET_USER_DETAIL} from "../actions";

const initialState = {
    userDetail:{}
}

const AuthReducer =  (state = initialState, action) => {
  switch (action.type) {
      case SET_USER_DETAIL:
          return {
              ...state,
              userDetail: action.payload.data
          }
      default:
        return state
  }
}

export default AuthReducer