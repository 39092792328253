import React from 'react'
import './VerfiyOtpComponent.scss'
import '../../assets/common/common_style.scss'

import clock from '../../assets/icons/otp-page-clock-redesign.svg'
import {useMediaQuery} from "react-responsive";
import OtpField from "react-otp-field";

const VerifyOtpComponent = (props) => {
	const {
		setOtpValue,
		time,
		resendOtpHandler,
		changeMobileNumber,
		otpValue
	} = props

	const isDesktopOrLaptop = useMediaQuery({minWidth: 1224})
	return (
		<div className={'verify-otp-component mx-auto'}>
			<div className={'text-center'}>
				<p className={'fabelle-primary-brown ' + (isDesktopOrLaptop ? 'euphemia-700-24 mb-2' : "euphemia-700-20 margin-bottom-20")}>Enter
					OTP</p>
				<p className={' fabelle-primary-brown ' + (isDesktopOrLaptop ? 'euphemia-400-14 margin-bottom-32' : "euphemia-400-12 margin-bottom-20")}>We
					have sent you SMS with 4 digit
					verification OTP on</p>

				<div className={'otp-input-container mb-2'}>
					<OtpField
						value={otpValue}
						onChange={setOtpValue}
						numInputs={4}
						onChangeRegex={/^([0-9]{0,})$/}
						autoFocus
						separator={null}
						isTypeNumber
						inputProps={{ className: 'otp-field__input', disabled: false }}
					/>
					{/*<OtpInput*/}
					{/*	inputStyle={{*/}
					{/*		width: '48px',*/}
					{/*		height: '48px',*/}
					{/*		marginTop: ' 0',*/}
					{/*		marginBotom: ' 8px',*/}
					{/*		marginLeft: `0`,*/}
					{/*		fontSize: '16px',*/}
					{/*		fontWeight: 500,*/}
					{/*		borderRadius: 12,*/}
					{/*		border: '1px solid rgba(0,0,0,0.3)',*/}
					{/*		outline: 'none',*/}
					{/*		backgroundColor: 'transparent'*/}
					{/*	}}*/}
					{/*	numInputs={4}*/}
					{/*	isDisabled={false}*/}
					{/*	// hasErrored={valid === false}*/}
					{/*	errorStyle={{*/}
					{/*		width: '48px',*/}
					{/*		height: '48px',*/}
					{/*		marginTop: ' 0',*/}
					{/*		marginBotom: ' 8px',*/}
					{/*		marginLeft: `0`,*/}
					{/*		fontSize: '16px',*/}
					{/*		fontWeight: 500,*/}
					{/*		borderRadius: 12,*/}
					{/*		border: '1px solid red',*/}
					{/*		outline: 'none'*/}
					{/*	}}*/}
					{/*	onChange={(e) => handleOtpChange(e)}*/}
					{/*	onKeyDown={(e) => {*/}
					{/*		if (e.key === 'Enter') {*/}
					{/*			verifyOtpOperation(e);*/}
					{/*		}*/}
					{/*	}}*/}
					{/*	separator={<span>{''}</span>}*/}
					{/*	isInputNum={true}*/}
					{/*	shouldAutoFocus={false}*/}
					{/*	otpEntered={(args) => setOtpEntered(args)}*/}
					{/*	setOtpValue={(args) => {*/}
					{/*		setOtpValue(args)*/}
					{/*	}}*/}
					{/*	resend={resendOtpState}*/}
					{/*	// setResend={()=>setResendOtpState(resendOtpState+1)}*/}
					{/*/>*/}
				</div>

				<div className={'d-flex justify-content-between margin-bottom-32  align-items-start'}>
					<div className={'d-flex align-items-center'}>
						<img src={clock} alt={'alt'} className={'icon-24 mr-2'}/>
						<p className={'trade-con-500-14 fabelle-primary-brown mb-0 opacity-60'}>{time.m}:{time.s}</p>
					</div>
					<div className={'text-right'}>
						<p className={'fabelle-primary-brown euphemia-400-14 mb-3 cursor-pointer'}
						   onClick={resendOtpHandler}>Send Again</p>
						<p className={'fabelle-primary-brown euphemia-700-14 mb-0 cursor-pointer'}
						   onClick={changeMobileNumber}>Change Number</p>
					</div>

				</div>
				{/*{isDesktopOrLaptop && <div className={'d-flex justify-content-center w-100'}>*/}
				{/*	<CustomButtonPrimary theme={'light'} name={"Continue"} disabled={verifyingOtp || !validOtp}*/}

				{/*	                     className={'w-100 euphemia-700-20'}*/}

				{/*	                     clickHandler={verifyOtpOperation}/>*/}
				{/*</div>}*/}
			</div>

		</div>
	)
}

export default VerifyOtpComponent
