import graphql from "babel-plugin-relay/macro";

const CollectionQuery=graphql`

    query CollectionQuery{
		collections {
            id
            name
            image
            desktopBanner
            mobileBanner
		}
	}
`

export default CollectionQuery
