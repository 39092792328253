import React from 'react'
import './InputComponent.scss'
import '../../assets/common/common_style.scss'

import {useMediaQuery} from "react-responsive";

import SendIcon from '../../assets/icons/send-icon.svg'

const InputComponent = (props) => {
	const {
		placeholder,
		onChangeHandler,
		clickHandler,
		value
	} = props
	const isDesktopOrLaptop = useMediaQuery({minWidth: 1024})
	return (
		<div className={'input-component d-flex news-letter-wrapper'}>
			<input placeholder={placeholder}
			       onChange={onChangeHandler}
			       value={value}
			       type={"text"} className={"euphemia-700-14 input-box w-100 px-0"}/>
			<img src={SendIcon}
			     onClick={clickHandler}

			     className={'cursor-pointer ' + (isDesktopOrLaptop ? ' px-4 py-2 golden-bg ' : 'py-3 px-2 golden-bg')}
			     alt={'alt'} style={{borderRadius:0}}/>
		</div>
	)
}

export default InputComponent
