import React, {useEffect} from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';
import reportWebVitals from './reportWebVitals';
import Routes from "./CombinedRoutes";
import {BrowserRouter as Router} from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'react-multi-carousel/lib/styles.css';

import "./assets/font/blairitc-tt-bold.ttf";
import "./assets/font/blairitc-tt-light.ttf";
import "./assets/font/Didot-Italic.ttf";
// import "./assets/font/Trade Gothic LT Light.ttf";
// import "./assets/font/Trade Gothic LT Bold.ttf";

import {Provider} from 'react-redux'
import {createStore} from 'redux'
import rootReducer from './store/reducers'
import {persistStore, persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {PersistGate} from 'redux-persist/integration/react';
import {devToolsEnhancer} from 'redux-devtools-extension';

const persistConfig = {
	key: 'root',
	storage,
	blacklist:["ModalReducer"]
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = createStore(persistedReducer, devToolsEnhancer());

const persistor = persistStore(store);

const routing = (
	// <ReactPWAInstallProvider enableLogging>
	<Provider store={store}>
		<PersistGate loading={null} persistor={persistor}>
			<Router>
				<Routes/>
			</Router>
		</PersistGate>
	</Provider>

);

document.addEventListener("wheel", function(event){
	if(document.activeElement.type === "number"){
		document.activeElement.blur();
	}
});

ReactDOM.render(
	routing,
	document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
