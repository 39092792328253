import {useEffect} from "react";
import {useLocation} from "react-router-dom";
import * as EmailValidator from 'email-validator';

export const capitalizeFirstLetter = s => s && s[0].toUpperCase() + s.slice(1).toLowerCase()

export const fieldLevelValidation = (touched, errors) => {

	if (touched !== undefined) {
		if (errors !== undefined) {
			return " errorInput"
		} else {
			return " selected"
		}
	} else {
		return ""
	}
}

export default function ScrollToTop({children}) {
	const {pathname} = useLocation();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [pathname]);

	return children || null;
}

export const secondsToTime = (secs) => {
	let hours = Math.floor(secs / (60 * 60));

	let divisor_for_minutes = secs % (60 * 60);
	let minutes = Math.floor(divisor_for_minutes / 60);

	let divisor_for_seconds = divisor_for_minutes % 60;
	let seconds = Math.ceil(divisor_for_seconds);

	let obj = {
		"h": hours,
		"m": minutes,
		"s": seconds
	};
	return obj;
}

export const checkItHas = (errors, args) => {
	if (errors) {
		return Object.keys(errors).includes(args)
	}

}

// DD-MONTH-YYYY
export const convertDateWithMonth = (str) => {
	let month = [
		"Jan",
		"Feb",
		"Mar",
		"Apr",
		"May",
		"Jun",
		"Jul",
		"Aug",
		"Sep",
		"Oct",
		"Nov",
		"Dec"
	]
	let date = new Date(str),
		mnth = (date.getMonth()),
		day = ("0" + date.getDate()).slice(-2);
	// debugger
	return [day, month[mnth], date.getFullYear()].join("-");
}

export const combineArrToSentence = (arr) => {
	let temp = ""
	for (let i = 0; i < arr.length; i++) {
		if(i < 1){
			temp = temp.concat(arr[i])
		}else{
			temp = temp.concat(" "+arr[i])
		}
		// temp = temp.concat(arr[i])
	}
	return temp
}
// YYYY-MM-DD
export const convertDateReverse = (str) => {
	let result
	let date = new Date(str),
		currentDate = new Date(),
		currentMnth = ("0" + (currentDate.getMonth() + 1)).slice(-2),
		currentDay = ("0" + currentDate.getDate()).slice(-2),
		mnth = ("0" + (date.getMonth() + 1)).slice(-2),
		day = ("0" + date.getDate()).slice(-2);
	if (str) {
		result = [date.getFullYear(), mnth, day].join("-")
	} else {
		result = [currentDate.getFullYear(), currentMnth, currentDay].join("-")
	}

	return result;
}

export function addDaysToDate(date, days){
	let res = new Date(date);
	res.setDate(res.getDate() + days);
	return res;
}


// convert json to object
export const convertJsonToObject = (str) => {
	return JSON.parse(str.replace(/\//g, ""))
}


export const  ValidateEmailAddress=(emailString)=>{
	// check for @ sign
	let atSymbol = emailString.indexOf("@");
	if(atSymbol < 1) return false;

	let dot = emailString.indexOf(".");
	if(dot <= atSymbol + 2) return false;

	// check that the dot is not at the end
	if (dot === emailString.length - 1) return false;

	if(dot){
		let after = emailString.split(".")[1];
		if(after.length < 2){
			return false
		}
	}

	if(emailString.indexOf(" ") !== -1){
		return false
	}

	if(emailString.indexOf("..") !== -1){
		return false
	}

	if(emailString.indexOf("@.") !== -1){
		return false
	}

	if(emailString.indexOf(".@") !== -1){
		return false
	}

	if(emailString.indexOf("@@") !== -1){
		return false
	}


	if(emailString.indexOf(".") === -1){
		return false
	}


	return true;
}

export const EmailValidatorPackage=(emailString)=>{
	return EmailValidator.validate(emailString);
}

export const convertReversedDateToDMonthY = (str) => {
	let dateOfArray = str.split("-")
	let date = dateOfArray[2],
		monthNumber = parseInt(dateOfArray[1]),
	 year = dateOfArray[0]
	let month = [
		"Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"][monthNumber - 1]
	return [date, month, year].join("-")
}